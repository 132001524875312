<template>
  <b-card 
  no-body
  class="mb-0">
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ $t('Table.Account.Title') }}</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" :to="{ name: 'setups-account-create' }">
              <span class="text-nowrap">{{ $t('Create') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table responsive :fields="fields" :items="items">
      <template #head()="scope">
          <div
            class="text-nowrap">
          {{ $t(`Table.Account.${scope.label}`) }}
          </div>
      </template>
      <template #cell(active)="data">
        <b-badge
          pill
          :variant="status[1][data.value]"
        >
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(id)="data">
        <div class="text-nowrap">
          <feather-icon
            @click="update(data.item.id)"
            :id="`account-row-${data.item.id}`"
            icon="EditIcon"
            class="cursor-pointer"
            size="20"
          />

          <feather-icon
            @click="showMsgBoxRemove(data.item.id)"
            :id="`account-row-${data.item.id}`"
            icon="TrashIcon"
            size="20"
            class="mx-2 cursor-pointer"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing entries</span> 
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BTable, BButton, BPagination, BBadge, BTooltip} from 'bootstrap-vue'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, 
    BRow, 
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BTooltip
  },
  data() {
    return {
      pageLength: 10,
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'active', label: 'Status' },
        { key: 'id', label: 'Actions' },
      ],
      items: [],
      status: [{
        false: 'Tidak Aktif', true: 'Aktif',
      },
      {
        false: 'light-danger', true: 'light-success',
      }],
      userData: getUserData()
    }
  },
  methods: {
    fetchDataListAccount() { 
      if(this.userData.role === 'admin'){
        moduleApi.fetchDataAccount()
        .then(response => {
            this.items  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
      } else {
        moduleApi.fetchDataAccountSearch({
          id_instansi: this.userData.idInstansi,
          role: this.userData.role
        })
        .then(response => {
            this.items  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
      }
    },
    update (id) {
      this.$router.replace({name: 'setups-account-update', params: { id: id }})
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.remove(id)
          }
        })
    },
    remove (id) {
      moduleApi.deletedAccount({
        id: id,
        deleted_at: new Date().toISOString()
      })
        .then( response => {
          this.fetchDataListAccount()
        })
        .catch( error => {
          console.log(error)
        })
    },
  },
  created () {
    this.fetchDataListAccount()
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>